var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reference-block"},[(_vm.userCan('container_platform_card_read_all'))?_c('div',{staticClass:"reference-block-link",attrs:{"id":"inventory-map"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'inventory' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('inventory'))+" "),(_vm.isActiveHotSpot('inventory')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('inventory'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),(_vm.userCan('container_platform_card_read_all'))?_c('div',{staticClass:"reference-block-link",attrs:{"id":"inventory-platforms"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'inventory_platform' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('inventory_platform'))+" "),(_vm.isActiveHotSpot('inventory_platform')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('inventory_platform'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),(_vm.userCan('inspectors_show') || _vm.userCan('incpectors_manage'))?_c('div',{staticClass:"reference-block-link",attrs:{"id":"inventory-inspectors"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'inventory_inspectors' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('inventory_inspectors'))+" "),(_vm.isActiveHotSpot('inventory_inspectors')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('inventory_inspectors'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e(),(_vm.userCan('inspectors_show'))?_c('div',{staticClass:"reference-block-link",attrs:{"id":"inventory-inspector-tasks"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'inspector_task' }}},[_c('div',{staticClass:"link-flex"},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('inspector_task'))+" "),(_vm.isActiveHotSpot('inspector_task')?.id)?_c('div',{staticClass:"hotspot",on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('inspector_task'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }