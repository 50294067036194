var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reference-block"},[_c('div',{staticClass:"reference-block-link",attrs:{"id":"notification-recipients"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'recipients' }}},[_c('div',{staticClass:"link-flex"},[_c('div',{staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('recipients'))+" ")]),(_vm.isActiveHotSpot('recipients')?.id)?_c('div',{staticClass:"hotspot",staticStyle:{"margin-left":"-20px"},on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('recipients'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1),_c('div',{staticClass:"reference-block-link",attrs:{"id":"notification-templates"}},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'notification-templates' }}},[_c('div',{staticClass:"link-flex"},[_c('div',{staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('notification-templates'))+" ")]),(_vm.isActiveHotSpot('notification-templates')?.id)?_c('div',{staticClass:"hotspot",staticStyle:{"margin-left":"-20px"},on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('notification-templates'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1),_c('div',{staticClass:"reference-block-link"},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'notifications' }}},[_c('div',{staticClass:"link-flex"},[_c('div',{staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('notifications'))+" ")]),(_vm.isActiveHotSpot('notifications')?.id)?_c('div',{staticClass:"hotspot",staticStyle:{"margin-left":"-20px"},on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('notifications'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }